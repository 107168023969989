/*---------------------------------------------------------------*/
/*--------------------------- BUTTONS ---------------------------*/
/*---------------------------------------------------------------*/

.green-button,
.primary-button,
.secondary-button {
  @apply inline-block font-semibold w-[inherit] transition-all rounded-sm py-2 px-6;
}

.primary-button {
  @apply text-black bg-white hover:bg-slate-200;
}
.secondary-button {
  @apply border border-gray-500 hover:bg-alpha rounded-full;
}

.green-button {
  @apply text-black bg-green-400;
}

/*---------------------------------------------------------------*/
/*--------------------------- RANGE SLIDER ----------------------*/
/*---------------------------------------------------------------*/
.range {
  @apply bg-white;
}

.range::-webkit-slider-thumb {
  background-color: red;
}
.range::-moz-range-thumb {
  background-color: red;
}
