@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-background text-white;
  @apply scroll-smooth;
}

.card:hover .content {
  @apply transition-all duration-300 bg-[#04040494] z-50;
}

.card:hover .content .description-top {
  @apply opacity-100 translate-y-10 transition-all duration-300;
}
.card:hover .content .description-bottom {
  @apply opacity-100 translate-y-[10rem] transition-all duration-300;
}

.card:hover .icon {
  @apply opacity-100;
}
.vedioCard:hover .top-description {
  @apply opacity-100;
}
.ol_trems {
  list-style-type: decimal;
  padding-left: 30px;
}
.ol_trems li {
  padding: 10px 0px;
}
.ul_trems {
  list-style-type: circle;
  padding-left: 30px;
}
.ul_trems li {
  padding: 10px 0px;
}
.head-ing {
  font-size: 14px;
}
.head-ing h2 {
  padding: 5px 0px;
}
.head-ing p {
  padding: 5px 0px;
}
.head-ing strong {
  text-align: center;
}
